<template>
  <b-container class="details-wrapper my-container">
    <v-progress-circular
      v-if="showLoader"
      indeterminate
      class="loader"
    ></v-progress-circular>
    <div v-else class="content-wrapper">
      <p class="back"></p>
      <div v-if="object" class="main">
        <photo-details :photos="object.photos" :name="object.name" />
        <div class="dsc">
          <card-details-component
            v-if="object"
            :object="object"
            :disable-button="disableButton"
            @quantityChanged="quantityChangedHandler"
            @cartError="error"
          ></card-details-component>
        </div>
      </div>
      <div class="tabs">
        <tabs-details
          v-if="object"
          :show-related-spinner="showRelatedSpinner"
          :axle="comboAxle"
          :combo="object.recordtype === 'kititem'"
          :combo-components="comboComponents"
          :acl="acl"
          :active="activeTab"
          :object="object"
          :applications="applications"
          @clickedTab="clickedTabHandler"
        />
      </div>
    </div>
    <universal-modal :modal="modal" @closeModal="closeModal" />
  </b-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import axios3C, { axiosBase } from "../axios";
import cfg from "./../3c.config";
import api from "../api";
import CardDetailsComponent from "@/components/details/CardDetails.vue";
import TabsDetails from "@/components/details/TabsDetails.vue";
import PhotoDetails from "@/components/details/PhotoDetails.vue";
import UniversalModal from "@/components/common/UniversalModal.vue";
import { mapState } from "vuex";

export default {
  name: "DetailsView",
  components: {
    CardDetailsComponent,
    TabsDetails,
    UniversalModal,
    PhotoDetails
  },
  mixins: [api],
  computed: {
    ...mapState(["lang"])
  },
  data() {
    return {
      cfg,
      axios3C,
      axiosBase,
      comboAxle: null,
      relatedRequested: false,
      showRelatedSpinner: false,
      activePhoto: "",
      acl: null,
      applications: [],
      activeTab: "overview",
      object: null,
      disableButton: false,
      showLoader: false,
      comboComponents: [],
      modal: {
        show: false,
        header: "",
        text: "",
        code: ""
      }
    };
  },
  watch: {
    "$route.params.id": function(record) {
      this.getPart(record);
    },
    lang: function() {
      this.getPart();
    }
  },
  created() {
    this.getPart();
  },
  methods: {
    getPart(record = this.$route.params.id) {
      this.showLoader = true;
      this.showRelatedSpinner = true;
      this.getItem(record)
        .then(res => {
          this.object = res.data;
          this.showLoader = false;
          return Promise.all([
            this.getRelatedItems(res),
            this.getApplications(res),
            this.getAcl(res),
            this.getAxleForIndividual(res),
            this.getComboIngredients(res)
          ]);
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.showRelatedSpinner = false;
        });
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      if (error.code === "AUTH001") {
        this.$store.commit("logOut");
      } else {
        this.modal.header = "Server error";
        this.modal.text = error.message || error.errmsg || error;
        this.modal.code = error.code;
        this.modal.show = true;
      }
    },
    clickedTabHandler(clickedTab) {
      this.activeTab = clickedTab;
    },
    closeModal() {
      this.modal.show = false;
    },
    quantityChangedHandler(quantity) {
      this.disableButton = true;
      axios3C
        .post("/item", {
          action: "getPrice",
          record: this.$route.params.id,
          quantity,
          customer: "guest",
          currency: this.cfg.CURRENCY
        })
        .then(res => {
          this.object = {
            ...this.object,
            price: { ...this.object.price, prices: res.data.prices },
            ...res.data
          };
        })
        .catch(err => {
          this.error(err);
        })
        .finally(() => {
          this.disableButton = false;
        });
    }
  }
};
</script>

<style lang="scss">
.details-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 10px;
  font-family: "Montserrat", "sans-serif";
  color: black;

  .tabs {
    margin-top: 30px;
    text-align: left;
  }

  .smallLoader {
    text-align: center;
  }

  .indexing {
    display: none;
  }

  .dsc2 {
    text-align: left;
    list-style-type: disc;
    padding-left: 20px;
    margin-top: -20px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
  }

  .loader {
    margin: 2em 0;
  }

  .content-wrapper {
    width: 100%;

    .back {
      text-align: left;
      margin-top: 10px;
      color: var(--primary);
      font: 14px "Montserrat";

      &:hover {
        text-decoration: underline;
      }
    }

    .panel-content {
      padding: 20px;

      .table {
        font-size: 12px;
        font-family: "Montserrat", sans-serif;
        text-align: left;
      }

      p:first-of-type {
        img {
          width: 100%;

          @media screen and (min-width: 600px) {
            width: auto;
          }
        }
      }
    }

    .main {
      @media screen and (min-width: 960px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2em;

        text-align: left;
        margin-top: 2em;
      }

      .btns {
        display: flex;
        width: 100%;
        button,
        input {
          height: 38px;
          border-radius: 0;
        }
        .button {
          background-color: var(--primary);
          border: none;
          text-transform: uppercase;
          font: 16px "Michroma";
          word-spacing: 6px;
        }
      }
      .dsc {
        .name {
          font: 22px "Michroma", sans-serif;
          font-weight: 500;
          word-spacing: 5px;
        }
        .custom-control-label {
          font-size: 0.8em;
          word-spacing: 3px;
        }
        .link {
          color: var(--primary);
          font-weight: bold;
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .delivery {
          background-color: #fafafa;
          .custom-control-label {
            line-height: 2;
          }
          .delivery-details {
            padding-left: 30px;
            padding-top: 10px;
            font-size: 0.8em;
            line-height: 0.5;
          }
        }
      }
    }
  }
}
</style>
