const SOURCE = "oz4x4eu";

let SPECIAL_DELIVERY_COUNTRIES = ["MT", "CY", "PL"];
let CURRENCY = "eur";
let URL_BASE = "https://oz4x4.eu";

export const PAYMENT_METHODS = {
  REVOLUT: "revolut",
  PAYPAL: "607468324e5b797a767fe87d"
};

export const WIDGET_TYPE = {
  REVOLUT: "Revolut",
  PAYPAL: "PayPal"
};

export default {
  SPECIAL_DELIVERY_COUNTRIES,
  SOURCE,
  CURRENCY,
  URL_BASE
};
